<template>
  <b-row>
    <b-col>
      <b-row
        class="mt-3 mb-5"
      >
        <b-col class="text-center h7">
          Describe your recruitment experience
        </b-col>
      </b-row>
      <b-row
        class="m-0 main-row p-3"
      >
        <b-col class="mt-3 mb-4">
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Your LinkedIn Profile(URL)
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTextInput
              v-model="linkedin"
              name="linkedin"
              placeholder="https://linkedin.com/in/123"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            lg="7"
          >
            <label class="h8">Where are you based?
              <span class="text-primary pl-1">*</span>
            </label>
            <b-form-select
              v-model="country"
              :options="countryOptions"
              class="select-small-font"
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
          >
            <label class="h8">Recruiter type
              <span class="text-primary pl-1">*</span>
            </label>
            <BadgeCheckbox
              v-model="type"
              name="type"
              :options="recruiterTypes"
              required
              :limit="1"
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
          >
            <label class="h8">
              Role preference
              <span class="font-weight-normal">(roles you are interested in recruiting)</span>
              <span class="text-primary pl-1">*</span>
            </label>
            <BadgeCheckbox
              v-model="roles"
              name="roles"
              :options="roleOptions"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            xl="7"
          >
            <label class="h8">Services provided – how can you help companies?
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTags
              v-model="services"
              name="services"
              :options="servicesOptions"
              button-text="Select services that apply"
              class="borderless-tags"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            xl="7"
          >
            <label class="h8">Industry preference
              <span class="font-weight-normal">(companies you are interested in)</span>
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTags
              v-model="industries"
              name="industries"
              :options="industryOptions"
              button-text="Select industries that apply"
              class="borderless-tags"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            xl="7"
          >
            <label class="h8">Region expertise
              <span class="font-weight-normal">(regions you've hired in)</span>
              <span class="text-primary pl-1">*</span>
            </label>
            <FormTags
              v-model="regions"
              name="regions"
              :options="regionsOptions"
              button-text="Select countries"
              class="borderless-tags"
              required
            />
          </b-col>
          <b-col
            class="mb-4"
            cols="12"
            xl="7"
          >
            <label class="h8">Country expertise
              <span class="text-primary pl-1">*</span>
            </label>
            <p class="h9">
              Name countries you’ve proven to deliver great results
            </p>
            <FormTags
              v-model="local_insights"
              name="local_insights"
              :options="countryOptions"
              button-text="Select countries"
              class="borderless-tags"
              required
            />
          </b-col>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import FormTextInput from '@/components/form/TextInput'
import FormTags from '@/components/FormTags'
import BadgeCheckbox from '@/components/form/BadgeCheckbox'
import { vuexMapper } from '@/helpers/vuexMapper'
import axios from '@/helpers/axios'

const SERVICE_OPTIONS_ORDER = {
  'Recruitment planning': 1,
  'Market research': 2,
  'Acquisition strategy': 3,
  Sourcing: 4,
  'Screening calls': 5,
  Interviewing: 6,
  'Pipeline management': 7,
  'Recruitment marketing': 8,
  'Employer branding': 9,
  'Salary negotiations': 10,
  'Recruitment software implementation': 11,
  'Recruitment process development': 12,
}

const GEO_REGIONS_ORDER = {
  Globally: 1,
  'North America': 2,
  'Central America': 3,
  'South America': 4,
  'The Caribbean': 5,
  Africa: 6,
  'Western Europe': 7,
  'Northern Europe': 8,
  'Eastern Europe': 9,
  'Southern Europe': 10,
  'Middle East': 11,
  'Central Asia': 12,
  'East Asia': 13,
  'South Asia': 14,
  'Southeast Asia': 15,
  Oceania: 16,
}

export default {
  name: 'RecruiterSignupDetailsStep1',
  components: {
    FormTextInput,
    FormTags,
    BadgeCheckbox,
  },
  data () {
    return {
      countryOptions: [],
      recruiterTypes: [
        { text: 'Freelancer', value: 'Freelancer' },
        { text: 'Interim', value: 'Interim' },
        { text: 'In-house', value: 'In-house' },
      ],
      servicesOptions: [],
      industryOptions: [],
      regionsOptions: [],
      roleOptions: [],
    }
  },
  computed: {
    ...vuexMapper({
      fields: [
        'linkedin',
        'country',
        'type',
        'services',
        'industries',
        'regions',
        'local_insights',
        'roles',
      ],
      base: 'recruiter',
      nestedObject: 'signupForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  created () {
    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        temp.unshift({ value: null, text: 'Select country' })
        this.countryOptions = temp
      })
    axios.get('/public/v2/values/services')
      .then(({ data }) => {
        // Creating temporary variable where to hold data/options
        let temp = this.mapValues(data)
        // mapping through temp and adding order key + value
        temp = temp.map(option => {
          option.order = SERVICE_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.servicesOptions = temp
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(industry => industry.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(industry => industry.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.industryOptions = temp
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = GEO_REGIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.regionsOptions = temp
      })
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        // Creating temporary variable where to hold data
        let temp = this.mapValues(data)
        // finding "Other" option obj. in array
        const findOtherOption = temp.find(role => role.text === 'Other')
        // Getting "Other" option obj. index in array
        const otherOptionIndex = temp.findIndex(role => role.text === 'Other')
        // Erasing "Other" obj from array
        temp.splice(otherOptionIndex, 1)
        // Sorting array by alphabetical order
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        // Adding "Other" option at the end of array
        temp.push(findOtherOption)
        // renaming/overwriting temporary variable
        this.roleOptions = temp
      })
  },
  methods: {
    mapValues (data) {
      const dataArr = data.map(option => {
        option.value = option.id
        option.text = option.label
        return option
      })
      return dataArr
    },
  },
}
</script>

<style lang="scss" scoped>
  .borderless-tags {
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .select-small-font {
    font-size: 0.9rem;
  }
  .main-row {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
  }
</style>
