/**
 * Map vuex data to form usable computed props with get() and set()
 *
 * @param {*} options
 * @param {string[]} options.fields Fields to map
 * @param {string} options.base Base path in store (module)
 * @param {string} options.nestedObject Nested object in store
 * @param {string} options.mutation Mutation to call
 * @returns vuex object
 */
export function vuexMapper (options) {
  // Initialize empty returnable object
  const remapped = {}

  // Loop over all fields
  for (let x = 0; x < options.fields.length; x++) {
    // Set current field to remap
    const field = options.fields[x]

    // Set getters and setters for field
    remapped[field] = {
      get () {
        if (options.nestedObject) {
          return this.$store.state[options.base][options.nestedObject][field]
        }
        return this.$store.state[options.base][field]
      },
      set (value) {
        this.$store.commit(`${options.base}/${options.mutation}`, {
          data: {
            field,
            value,
          },
          nestedObject: options.nestedObject,
        })
      },
    }
  }

  // Return al remapped fields as single object
  return remapped
}
