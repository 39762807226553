<template>
  <div class="stepper-wrapper">
    <div
      class="stepper-item"
      :class="{
        'completed': value > 0,
        'active': value === 0
      }"
    >
      <div class="step-counter" />
      <div class="step-name text-center">
        Describe your recruitment <br> experience
      </div>
    </div>
    <div
      class="stepper-item"
      :class="{
        'completed': value > 1,
        'active': value === 1,
        'text-muted': value < 1,
      }"
    >
      <div class="step-counter" />
      <div class="step-name text-center">
        Availability, payment and <br> languages
      </div>
    </div>
    <div
      class="stepper-item"
      :class="{
        'completed': value > 2,
        'active': value === 2,
        'text-muted': value < 2,
      }"
    >
      <div class="step-counter" />
      <div class="step-name text-center">
        About you and description
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProgressBar2',
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    localStep: {
      get () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    makeCompleted () {
      this.active = !this.active
    },
    prev () {
      if (this.value > 0) {
        this.localStep--
      }
    },
    next () {
      if (this.value < this.maxSteps) {
        this.localStep++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$stepColor: #EE8B7B;

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  line-height: 16.8px;
  // font-weight: 400;
  font-size: 14px;
  color: #323031;
  font-family: Jost;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &::before {
    position: absolute;
    content: "";
    border-bottom: 4px solid #ccc;
    width: 70%;
    top: 8px;
    left: -35%;
    z-index: 2;
    border-radius: 10px;
  }
  &::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid $stepColor;
    width: 70%;
    top: 8px;
    left: 65%;
    z-index: 2;
    border-radius: 10px;
  }
  &.active {
    font-weight: bold !important;
  }
  &.completed .step-counter {
    background-color: $stepColor;
    width: 20px;
    height: 20px;
    border: 10px solid $stepColor;
    transition: .4s ease;
  }
  &.completed::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid $stepColor;
    width: 70%;
    top: 8px;
    left: 65%;
    z-index: 3;
    border-radius: 10px;
  }

  &.active .step-counter {
    border: 4px solid $stepColor;
    background-color: #faf4f2;
  }

  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #faf4f2;
    border: 4px solid #DADADA;
    background-color: #DADADA;
    margin-bottom: 6px;
  }
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
</style>
