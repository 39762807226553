<template>
  <b-form-textarea
    :value="value"
    :name="name"
    :required="required"
    :placeholder="placeholder"
    no-outer-focus
    :rows="rows"
    @input="input"
    v-on="listeners"
  />
</template>

<script>
export default {
  name: 'FormTextArea',
  props: {
    name: {
      type: String,
      requried: false,
      default: '',
    },
    required: {
      type: Boolean,
      requried: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    rows: {
      type: Number,
      requried: false,
      default: 3,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
