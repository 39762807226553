<template>
  <b-form-checkbox-group
    v-if="limit > 1"
    v-model="localValue"
    :name="name"
    class="form-pills-component mb-2 h9"
    buttons
    :required="required"
    :checked="value"
    v-on="listeners"
  >
    <b-form-checkbox
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="!value.includes(option.value) && value.length >= limit"
    >
      {{ option.text }}
    </b-form-checkbox>
  </b-form-checkbox-group>
  <b-form-group
    v-else
    class="form-pills-component mb-2 h9"
  >
    <b-form-radio-group
      v-model="localValue"
      buttons
      :name="name"
      :options="options"
      :required="required"
      :checked="value"
      v-on="listeners"
    />
  </b-form-group>
</template>

<script>
// @eslint-disable
export default {
  name: 'BadgeCheckbox',
  props: {
    name: {
      type: String,
      requried: false,
      default: '',
    },
    required: {
      type: Boolean,
      requried: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: 999999,
    },
    value: {
      type: [Array, String],
      required: false,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        // Check if value is array
        if (!Array.isArray(value)) {
          console.warn('Options must be array')
          return false
        }
        // In case options has values, it must be array of objects with text and value
        if (value.length) {
          if (value[0].value === undefined && value[0].text === undefined) {
            console.warn('Options must be array of objects with "text" and "value" keys')
            return false
          }
        }
        return true
      },
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    localValue: {
      get () {
        return this.value
      },
      set (newVal) {
        // Check if return value should be string or array
        if (this.limit === 1) {
          if (Array.isArray(newVal)) {
            this.$emit('input', newVal[0])
            return
          }
        }
        this.$emit('input', newVal)
      },
    },
  },
}
</script>

<style lang="scss">

</style>
