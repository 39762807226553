var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper-wrapper"},[_c('div',{staticClass:"stepper-item",class:{
      'completed': _vm.value > 0,
      'active': _vm.value === 0
    }},[_c('div',{staticClass:"step-counter"}),_vm._m(0)]),_c('div',{staticClass:"stepper-item",class:{
      'completed': _vm.value > 1,
      'active': _vm.value === 1,
      'text-muted': _vm.value < 1,
    }},[_c('div',{staticClass:"step-counter"}),_vm._m(1)]),_c('div',{staticClass:"stepper-item",class:{
      'completed': _vm.value > 2,
      'active': _vm.value === 2,
      'text-muted': _vm.value < 2,
    }},[_c('div',{staticClass:"step-counter"}),_c('div',{staticClass:"step-name text-center"},[_vm._v(" About you and description ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-name text-center"},[_vm._v(" Describe your recruitment "),_c('br'),_vm._v(" experience ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-name text-center"},[_vm._v(" Availability, payment and "),_c('br'),_vm._v(" languages ")])}]

export { render, staticRenderFns }